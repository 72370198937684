import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {useNavigate } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import '../Styles/SideBar.css';
import UserType from '../Helpers/getUserType';
const SideBar = ({tabSelected, setTabSelected}) => {
    const [tabList, setTabList] = useState([
        {
            id: 1,
            name : 'Dashboard',
            icon : require('../assets/dashboard.svg').default
        },
        {
            id: 2,
            name : 'Market',
            icon : require('../assets/trade.svg').default
        },
        // {
        //     id: 3,
        //     name : 'Settings',
        //     icon : require('../assets/settings.svg').default
        // },
        {
            id: 4,
            name : 'Profile',
            icon : require('../assets/profile.svg').default
        }
    ])

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const navigate = useNavigate();

    const handleTabClick = (tabName) => {
        setTabSelected(tabName);
        let token = Cookies.get('token')
        let userType = UserType(token)
        console.log('userType###########', userType);
        setOpen(false);
        if(tabName == 'Dashboard' && userType == 'USER') navigate('/')
        else if(tabName == 'Dashboard' && userType == 'ADMIN') navigate('/admin/home')
        else if(tabName == 'Profile') navigate('/profile')
        else if(tabName == 'Market') navigate('/trade')
    };

    const Logout = () => {
        Cookies.remove('token');
        navigate('/login')
    }

    const sideBarOptions = () => {
        return (
            <div className='flexCol splitCards'>
                <div className='flexCol g12' style={{paddingTop:24}}>
                    <div className='noDesktop flexRow' style={{justifyContent:'space-between', alignItems:'center'}}>
                        <div className='logoTitle'>
                                    <img style={{width:100, height:24}} src={require('../assets/temp.svg').default} />
                                    {/* <span className='name'>{tab.name}</span> */}
                                </div>
                        <div 
                        onClick={toggleDrawer(false)}
                        className='flexRow' style={{alignItems:'center',justifyContent:'flex-end', }}>
                            <span style={{color:'#E31892', paddingLeft:12, fontSize:20, fontWeight:600}}>X</span>
                        </div>
                    </div>
                    {
                        tabList.map((tab, index) => {
                            return (
                                <div onClick={() => {handleTabClick(tab.name)}} className={`flexRow g10 ${tabSelected === tab.name ? 'activeCard' : 'card'}`}>
                                    <img style={{width:24, height:24}} src={tab.icon} />
                                    <span className='name'>{tab.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <div onClick={Logout} className='flexRow g10 card'>
                        <img style={{width:24, height:24}} src={require('../assets/logout.svg').default} />
                        <span className='name'>Logout</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='noDesktop'>
                <div class="navbar" onClick={toggleDrawer(true)}>
                    <div class="hamburger-menu" >
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
                <Drawer open={open} onClose={toggleDrawer(false)} style={{backgroundColor:'black'}}>
                    <div className='sideBar'>
                        {
                            sideBarOptions()
                        }
                    </div>
                </Drawer>
            </div>
            <div className='noMobile'>
                <div className='sideBar'>
                <div className='logoTitle'>
                    <img style={{width:100, height:25}} src={require('../assets/temp.svg').default} />
                    {/* <span className='name'>{tab.name}</span> */}
                </div>
                    {
                        sideBarOptions()
                    }
                </div>
            </div>
        </div>
        
    )
}

export default SideBar;