const TokenParser = (token) => {
    if (!token) return {};
    const [headerEncoded, payloadEncoded, signatureEncoded] = token ? token.split('.') : [];
    
    const header = JSON.parse(atob(headerEncoded));
    const payload = JSON.parse(atob(payloadEncoded));
    
    return { header, payload };
}

export default TokenParser;