// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #080808;
    
}

.homeContainer{
    height: 100vh;
    display: flex;
    flex-direction: row; 
}`, "",{"version":3,"sources":["webpack://./src/Styles/Home.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["body {\n    background-color: #080808;\n    \n}\n\n.homeContainer{\n    height: 100vh;\n    display: flex;\n    flex-direction: row; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
