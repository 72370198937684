import '../Styles/ButtonLoader.css'
const ButtonLoader = () => {

    return (
        <div className="loader">

        </div>
    )
}

export default ButtonLoader;