import moment from 'moment-timezone';
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "../Styles/Dashboard.css";
import { capitalize } from "@mui/material";

const Trade = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [time, setTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(30)
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  const handleTradeAPIThrottling = async () => {
    setTime('... ...');
    await sleep(60000)
    getTradeData();
  }
  const getTradeData = () => {
    setLoading(true);
    let token = Cookies.get("api_token");
    // let headers = {
    //   Authorization: Bearer ${token},
    // };
    const timestamp = new Date().getTime();
    axios
      .get(`https://fcsapi.com/api-v3/crypto/supply?sort=rank&order=ASC&limit=${limit}&access_key=` + token + `&timestamp==${timestamp}`)
      .then((apiResponse) => {
	if(apiResponse.data?.code == 213) {
          handleTradeAPIThrottling();
        } else {
       // console.log("apiResponse", apiResponse.data);
        let latestTime = apiResponse.data?.info?.server_time;
        setTime(moment.utc(latestTime).tz('America/Chicago').format('YYYY-MM-DD HH:mm:ss'))
        let data = apiResponse.data.response;
        setData(data);
        setLoading(false);}
      })
      .catch((apiError) => {
        setLoading(false);
        console.log("apiError", apiError);
        if (apiError.response.status === 401) {
          navigate("/login");
        }
      });
  };

  const getMoreData = () => {
    let temp = limit;
    temp = temp + 20;
    setLimit(temp);
    getTradeData();
  }

  useEffect(() => {
    getTradeData();
  }, []);

  const renderTableData = () => {
    return (
      <div className="mobileTable currencyTable">
        <table className="table">
          <thead>
            <tr>
              <th align="center" className ="header-name">Name</th>
              <th align="center">Symbol</th>
              <th align="center">Price</th>
              <th align="center">Last 1hr (%)</th>
              <th align="center">Last 24hr (%)</th>
              <th align="center">Last 1week (%)</th>
              <th align="center">Volume in 24hr</th>
              <th align="center">Market Cap</th>
             
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div className="skeleton">
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
              </div>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td data-label="Name" align="center" className = "bold-and-large">
                    <b>{item.name}</b>
                  </td>
                  <td data-label="Symbol" align="center" className = "bold-and-large">
                    <b>{item.symbol}</b>
                  </td>
                  <td data-label="Price" align="center" className = "bold-and-large">
                   <b>  ${Intl.NumberFormat("en-US", {  maximumFractionDigits:10  } ).format(item.quote.USD.price)}</b>
                   {/* <b> ${item.quote.USD.price}</b> */}
                  </td>
                  <td data-label="Last 1hr (%)" align="center" className = "bold-and-large">
                    {item.quote.USD.percentage_change_1h > 0 ? (
                      <span className="profit">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/profitamount.svg").default}
                        /> */}
                        + {item.quote.USD.percentage_change_1h}
                      </span>
                    ) : item.quote.USD.percentage_change_1h < 0 ? (
                      <span className="loss">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/lossamount.svg").default}
                        /> */}
                        {item.quote.USD.percentage_change_1h}
                      </span>
                    ) : (
                      <span>{item.quote.USD.percentage_change_1h ? item.quote.USD.percentage_change_1h : 0}</span>
                    )}
                  </td>
                  <td data-label="Last 24hr (%)" align="center" className = "bold-and-large">
                    {item.quote.USD.percentage_change_24h > 0 ? (
                      <span className="profit">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/profitamount.svg").default}
                        /> */}
                        +{item.quote.USD.percentage_change_24h}
                      </span>
                    ) : item.quote.USD.percentage_change_24h < 0 ? (
                      <span className="loss">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/lossamount.svg").default}
                        /> */}
                        {item.quote.USD.percentage_change_24h}
                      </span>
                    ) : (
                      <span>
                        {item.quote.USD.percentage_change_24h ? item.quote.USD.percentage_change_24h : 0}
                      </span>
                    )}
                  </td>
                  <td data-label="Last 1week (%)" align="center" className = "bold-and-large">
                    {item.quote.USD.percentage_change_7d > 0 ? (
                      <span className="profit">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/profitamount.svg").default}
                        /> */}
                        {item.quote.USD.percentage_change_7d}
                      </span>
                    ) : item.quote.USD.percentage_change_7d < 0 ? (
                      <span className="loss">
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={require("../assets/lossamount.svg").default}
                        /> */}
                        {item.quote.USD.percentage_change_7d}
                      </span>
                    ) : (
                      <span>{item.quote.USD.percentage_change_7d ? item.quote.USD.percentage_change_7d : 0}</span>
                    )}
                  </td>
                  <td data-label="Vol. 24hr" align="center" className = "bold-and-large">
                      ${Intl.NumberFormat("en-US", {  maximumFractionDigits:10  }).format(item.quote.USD.vol_24H)}
                  </td>
                  <td data-label="Market Cap" align="center" className = "bold-and-large">
                    ${Intl.NumberFormat("en-US", {  maximumFractionDigits:10  }).format(item.quote.USD.market_cap)}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="dashboard">
      <div>
        <div className="dashboarddetails">
          <div
            className="flexRow"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div>
              <span className="dashboardtype leftalign" style={{ padding: 12 }}>
                Currencies
              </span>
            </div>
            <div className='flexCol' style={{alignItems:'flex-end', justifyContent:'center', paddingRight:12}}>
              <div>
                <Button
                  size="small"
                  variant="contained"
                  onClick={getTradeData}
                  style={{
                    backgroundColor: "#7636CE",
                    textTransform: "capitalize",
                  }}
                >
                  Refresh
                </Button>
              </div>
              <div>
                <span
                style={{ color: "#FFF", fontSize: 12, fontWeight: 600 }}
                >
                Updated at :
                  <span
                    style={{ color: "#72FF91", fontSize: 12, fontWeight: 600 }}
                  >
                    {` ${time.split(' ')[0]} at ${time.split(' ')[1]}`} 
                  </span>
                </span>
              </div>
            </div>
          </div>
          {renderTableData()}
          {/* <div>
            <Button
              size="medium"
              variant="contained"
              onClick={getMoreData}
              style={{
                backgroundColor: "#E31892",
                textTransform: "capitalize",
              }}
            >
              Load More
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Trade
