import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const LineGraph = ({ dropdownSelection }) => {
  // Sample data for demonstration
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Balance Over Time',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  });

  const location = useLocation();

  // Extract the token from the query string
  const pathSegments = location.pathname.split('/');
  const userId = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = Cookies.get('token');
        let headers = {
          Authorization: `Bearer ${token}`,
        };
        if(userId && userId !== '') {
          headers["userId"] = userId;
        }
        const response = await axios.get(`https://backend.luxara.ai/api/v1/stats?timeframe=${dropdownSelection}`, {headers : headers});
        const stats = response.data.stats;

        const chartData = {
          labels: stats.map(stat => stat.date),
          datasets: [
            {
              ...data.datasets[0],
              data: stats.map(stat => stat.balance),
            },
          ],
        };

        setData(chartData);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [dropdownSelection ]);

  // Chart options
  const options = {
    scales: {
      x: {
        type: 'category', // Keep this as category type for better control over labels
        ticks: data.labels.length > 5 ? {
          autoSkip: true,       // Automatically skip some labels
          maxRotation: 90,      // Maximum rotation of 45 degrees for labels
          minRotation: 45,       // Minimum rotation of 0 degrees for small screens
          callback: function(value, index, values) {
            // Show only every 2nd label to avoid overlap (you can adjust this condition)
            return index % 2 === 0 ? this.getLabelForValue(value) : '';
          },
        } : {},
      },
    },
  };

  return <Line data={data} options={options}/>;
};

export default LineGraph;
