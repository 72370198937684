import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import '../Styles/Login.css';
import UserType from '../Helpers/getUserType';
import ButtonLoader from '../Helpers/buttonLoader';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const location = useLocation();

    // Extract the token from the query string
    const queryParams = new URLSearchParams(location.search);
    const resetPassToken = queryParams.get('token');
   

    useEffect(() => {
        // const tokenExists = Cookies.get('token') !== undefined;
        // if (tokenExists) {
        //   navigate('/');
        // }
      }, []);
    
      const handleUpdatePassword = () => {
        setLoading(true);
        let headers = {
            // Authorization : `Bearer ${token}`
        }
        let apiRequest = {
            "newPassword" : confirmPassword
        }
        axios.post(`https://backend.luxara.ai/api/v1/resetPassword?token=${resetPassToken}`, apiRequest, {headers: headers})
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            setLoading(false);
            navigate('/login')
            // let token = apiResponse.data.token;
            // Cookies.set('token', token);
            // let userType = UserType(token);
            // setLoading(false);
            // if(userType === 'ADMIN'){
            //     navigate('/admin/home')
            // } else if (userType === 'USER') {
            //     if(apiResponse.data?.isNewUser) setInitialLogin(true);
            //     else navigate('/')
            // }
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError', apiError.messsage);
            setError(apiError.messsage);
            setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
    };

    
    const validateFields = () => {
        // console.log('$$$$$$4check', !password ||
        // !newPassword || 
        // !confirmPassword || 
        // newPassword !== confirmPassword)
        return (
            !newPassword || 
            !confirmPassword || 
            newPassword !== confirmPassword
        )
    }

    const buttonStyle = {
        backgroundColor: '#7636CE',
        color: 'white',
        borderRadius: '16px', // rounded corners
        '&:hover': {
          backgroundColor: '#5b2d9e', // darker shade on hover
        },
      };
    const textfieldStyle = {
        borderRadius: '4px',
        border:'none',
        backgroundColor: '#FFFFFF'
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='mainLogin'>
            <div className='leftContainer'>
                <img className='fullImage' src={require('../assets/logoBanner.svg').default} alt="Crypto Banner" />
            </div>
            <div className='loginContent'>
                    <div className='loginContainer'>
                    <div style={{display:'flex',flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                            <div>
                                <span className='hello'>
                                    Reset Password
                                </span>
                            </div>
                        </div>
                        <div style={{width:'100%', position : 'relative'}}>
                            <input type="password"  
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="UsenameField" placeholder="New password" />
                        </div>
                        <div style={{width:'100%'}}>
                            <input type="password"  
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="UsenameField" placeholder="Confirm password" />
                        </div>
                        <div className='flexRow alignCenter'>
                            {
                                loading ? 
                                <div className='LoginButton flexRow alignCenter'>
                                    <ButtonLoader />
                                </div>
                                :
                                <button
                                // disabled={validateFields}
                                // style={{backgroundColor: (validateFields) ? 'gray' : 'red'}}
                                onClick={handleUpdatePassword}
                                className='LoginButton'>Update password & Login</button>
                            }
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default ResetPassword;
