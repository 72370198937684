import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../Styles/Home.css';

import SideBar from '../Components/SideBar';
import TopBar from '../Components/TopBar';
import Dashboard from './DashBoard';
import UserDashboard from './UserDashBoard';
import UserType from '../Helpers/getUserType';

const Home = () => {
  const location = useLocation();
  const { pathname } = location;
  const [tabSelected, setTabSelected] = useState(
    pathname === '/' ? 'Dashboard' 
    : pathname === '/profile' ? 'Profile'
    : pathname === '/admin/home' ? 'Dashboard'
    : pathname === '/trade' ? 'Trade' 
    : '');

  useEffect(() => {
    const tokenExists = Cookies.get('token') !== undefined;
    if (!tokenExists) {
      navigate('/login');
    }
  }, []);

  const navigate = useNavigate();

  const getDetails = () => {
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className='homeContainer'>
      <SideBar tabSelected={tabSelected} setTabSelected={setTabSelected} />
      <div className='flexCol' style={{ flex: 1 }}>
        <TopBar tabSelected={tabSelected} setTabSelected={setTabSelected}/>
        <Outlet />
        {/* {
                    tabSelected == 'Dashboard' && user == 'Admin'? 
                    <Dashboard />
                    : 
                    tabSelected == 'Dashboard' && user == 'User'?
                    <UserDashboard />
                    : ''
                } */}
      </div>
    </div>
  );
};

export default Home;
