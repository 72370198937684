import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../Helpers/buttonLoader';
import '../Styles/AddUser.css'
import Cookies from 'js-cookie';

const AddUser = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subscriptionPeriod,setsubscriptionPeriod] = useState('');
    const [balance, setBalance] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAddUser = () => {
        setErrorMessage('');
        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            'Authorization': `Bearer ${token}`
        }
        let apiRequest = {
            "email" : email,
            "username" : name,
            "phoneNumber" : phoneNumber,
            "subscriptionPeriod": Number(subscriptionPeriod),
            "balance" : Number(balance)
        }
        console.log('$$$$$$$apiRequest', apiRequest)
        axios.post('https://backend.luxara.ai/api/v1/users', apiRequest, {headers:headers})
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            setLoading(false);
            navigate('/admin/home');
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError', apiError.response.data.message);
            setErrorMessage(apiError?.response?.data?.message);
            setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
    }

    return (
        <div className='dashboard flexRow' style={{justifyContent:'center'}}>
            <div className='userdetails'>
                <div className='dashboarddetails flexCol'>
                    <div className='flexRow' style={{justifyContent:'space-between', alignItems:'flex-start'}}>
						<div>
							<span className='dashboardtype leftalign' style={{padding:12, paddingTop:0, paddingBottom:24}}>Hello Admin</span>
						</div>
					</div>
                    <div className='flexRow' style={{justifyContent:'center'}}>
                        <div className='AddUserContainer'>
                            <div>
                                <span className='addusername'>Add New User</span>
                            </div>
                            <hr style={{border: '1px solid #F5F5F5',width:'100%'}}></hr>
                            <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                <span className='adduserfieldName'>FullName</span>
                                <input 
                                type='text'
                                value={name}
                                onChange={(e) => 
                                    {
                                        setName(e.target.value)
                                        setErrorMessage('');
                                    }
                                }
                                placeholder="Full Name"
                                className='adduserInput'
                                />
                            </div>
                            <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                <span className='adduserfieldName'>Email Address</span>
                                <input 
                                type='email'
                                value={email}
                                onChange={(e) => 
                                    {
                                        setEmail(e.target.value)
                                        setErrorMessage('');
                                    }
                                }
                                placeholder="Email address"
                                className='adduserInput'
                                />
                            </div>
                            <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                <span className='adduserfieldName'>Phone Number</span>
                                <input 
                                type="tel" 
                                value={phoneNumber} 
                                onChange={(e) =>
                                    {
                                         setPhoneNumber(e.target.value)
                                         setErrorMessage('');
                                    }
                                }
                                placeholder="Phone Number"
                                className='adduserInput'
                                />
                            </div>
                            <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                <span className='adduserfieldName'>Cycle</span>
                                <input 
                                type='number'
                                value={subscriptionPeriod}
                                onChange={(e) => {
                                    setsubscriptionPeriod(e.target.value);
                                    setErrorMessage('');
                                }}
                                placeholder="Cycles Left"
                                className='adduserInput'
                                />
                            </div>
                            <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                <span className='adduserfieldName'>Balance</span>
                                <input 
                                type='number'
                                value={balance} 
                                onChange={(e) => 
                                    { 
                                        setBalance(e.target.value);
                                        setErrorMessage('');
                                    }
                                }
                                placeholder="Balance"
                                className='adduserInput'
                                />
                            </div>
                            {
                                errorMessage && 
                                <div className='flexCol' style={{alignItems:'flex-start', justifyContent:'center', gap:12, width:'100%'}}>
                                    <span className='adduserfieldName' style={{color:'red'}}>{errorMessage}</span>
                                </div>
                            }
                            <div className='flexRow' style={{justifyContent:'space-around', alignItems: 'center', width:'100%'}}>
                                <div>
                                    <button onClick={() => {navigate('/admin/home')}} className='cancelButton'>Cancel</button>
                                </div>
                                {
                                        loading ? 
                                        <div className='AddUserButton flexRow alignCenter'>
                                            <ButtonLoader />
                                        </div>
                                        :
                                        <div>
                                            <button onClick={handleAddUser}
                                            className='AddUserButton'>Add User</button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser;