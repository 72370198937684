const UserType = (token) => {
    const [headerEncoded, payloadEncoded, signatureEncoded] = token.split('.');
    
    const header = JSON.parse(atob(headerEncoded));
    const payload = JSON.parse(atob(payloadEncoded));
    console.log('header', header);
    console.log('payload', payload);
    if(payload?.role == 'user') return 'USER';
    else if(payload?.role == 'admin') return 'ADMIN';
    else return 'INVALID';
}

export default UserType;