import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../Styles/Dashboard.css';
import UserType from '../Helpers/getUserType';
import ButtonLoader from '../Helpers/buttonLoader';
import Switch from 'react-switch';
const Dashboard = () => {
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [balanceUpdate, setBalanceUpdate] = useState(false);
	const [currentUser, setCurrentUser] = useState({});
	const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectType, setSelectType] = useState('Profit');
	const [buttonLoader, setButtonLoader] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [recordsFiltered, setRecordsFiltered] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [balance, setBalance] = useState(0);

	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();

	const recordsPerPage = 10;


	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const getUsers = (page = 1, searchValue = '') => {
		setLoading(true);
		let token = Cookies.get('token');
		let headers= {
			Authorization : `Bearer ${token}`
		}
		axios.get(`https://backend.luxara.ai/api/v1/users?start=${(currentPage - 1) * recordsPerPage}&searchValue=${searchValue}`, {headers: headers})
        .then((apiResponse)=>{
			setData(apiResponse.data.users);
			setRecordsFiltered(apiResponse.data.recordsFiltered);
			setLoading(false);
        })
        .catch((apiError)=>{
			setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
	}

	const onSearchChange = (event) => {
		setSearchValue(event.target.value);
		setCurrentPage(1); // Reset to page 1 when search changes
	};

	useEffect(()=>{
        let token = Cookies.get('token');
        if(token){
            let userType = UserType(token);
            if(userType === 'USER') navigate('/home');
            else if(userType !== 'ADMIN') navigate('/login')
        }
    },[])

	useEffect(()=>{
		getUsers(currentPage, searchValue);
	}, [currentPage])

	useEffect(()=>{
		renderTableData();
	}, [data])

	const onDeleteAction = () => {
		// Create a shallow copy of the data array
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		};
		let apiRequest = {
			data: {
				userId: data[currentIndex]?.id
			},
			headers: headers
		};
		console.log(headers, apiRequest);
		axios.delete('https://backend.luxara.ai/api/v1/users', apiRequest)
			.then((apiResponse) => {
				
				// let tempdata = [...data];
				// // Remove the item at the specified index
				// tempdata.splice(currentIndex, 1);
				// // Update the state with the new array
				// setData(tempdata);
				getUsers(currentPage, searchValue);
				setOpenDelete(false);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	};
	

	const ViewDetails = (item ) => {
		navigate(`/admin/users/${item.id}`);
		// setLoading(true)
		// setFirstName('');
		// setLastName('')
		// setEmail('')
		// setMobileNumber('')
		// setBalance('')
		// setOpenView(true);
		// console.log('item', item)
		// let token = Cookies.get('token');
		// let headers = {
		// 	Authorization: `Bearer ${token}`
		// }
		// axios.get(`https://backend.luxara.ai/api/v1/getUserProfile?id=${item.id}`, {headers:headers})
		// .then((apiResponse)=>{
        //     console.log('apiResponse$$$$', apiResponse.data);
		// 	setFirstName(apiResponse.data?.username);
		// 	setLastName(apiResponse.data?.username)
		// 	setEmail(apiResponse.data?.email)
		// 	setMobileNumber(apiResponse.data?.phoneNumber)
		// 	setBalance(apiResponse.data?.balance)
		// 	setLoading(false);
		// 	// setOpenView(true);
        // })
        // .catch((apiError)=>{
		// 	setLoading(false);
        //     console.log('apiError', apiError.message);
        //     // console.log('apiError', apiError.response);
        //     // console.log('apiError', apiError.response.status);
        //     // console.log('apiError', apiError);
		// 	if(apiError.response.status === 401){
		// 		navigate('/login')
		// 	}
        // })
	}

	const handleOpenTradeUpdate = (item, index) => {
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`,
			userId: data[index]?.id,
		};

		let newData = new FormData();
        newData.append("request", JSON.stringify({
			enableTrade : !item.enableTrade
		}));
		axios.post('https://backend.luxara.ai/api/v1/updateUserProfile',newData, {headers: headers})
			.then((apiResponse) => {
				let tempdata = [...data];
				// Remove the item at the specified index
				item.enableTrade = !item.enableTrade;
				tempdata[index] = item;
				// Update the state with the new array
				setData(tempdata);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	} 

    const renderTableData = () => {
		// let renderData = searchValue ? searchUsers(searchValue) :
		// 				currentPage ? data.slice(((currentPage-1)*5), (currentPage*5)) : data;
        return (
			<div className='mobileTable userDataTable'>
				<table className="table">
				<thead>
					<tr>
					<th className='userDataTableHeader'>Name</th>
					<th className='userDataTableHeader'>Balance</th>
					<th className='userDataTableHeader'>Profit/ Loss</th>
					<th className='userDataTableHeader'>Cycle</th>
					<th className='userDataTableHeader'>Open Trades</th>
					<th className='userDataTableHeader'>Enable Trading</th>
					<th className='userDataTableHeader'>Action</th>
					<th className='userDataTableHeader'>Remove</th>
					</tr>
				</thead>
				<tbody>
				{loading ? (
					<div className="skeleton">
					<div className="skeleton-line"></div>
					<div className="skeleton-line"></div>
					<div className="skeleton-line"></div>
					<div className="skeleton-line"></div>
					<div className="skeleton-line"></div>
					<div className="skeleton-line"></div><div className="skeleton-line"></div>
					<div className="skeleton-line"></div>
					</div>
				) : (
					data.map((item, index) => (
					<tr key={index}> {/* Make sure to add a key here */}
						<td style={{cursor: 'pointer'}}
						onClick={() => {ViewDetails(item)}}
						// style={{color:'#E31892', textDecoration:'underline', cursor:'pointer'}}
						data-label="Name" className ="UserdetailsField">{item.userName}
						</td>
						<td data-label="Balance" className ="UserdetailsField">{item.balance}</td>
						<td data-label="Profit/ Loss" className ="UserdetailsField">
						  {
							item.profitLossType === "Profit"  ? 
							<span className='profit'>
							  {/* <img style={{width:24, height:24}} src={require('../assets/profitamount.svg').default}/> */}
							  { item.profitLossAmount}</span>
							:
							item.profitLossType === "Loss"  ? 
							<span className='loss'>
							  {/* <img style={{width:24, height:24}} src={require('../assets/lossamount.svg').default}/> */}
							  {(item.profitLossAmount)}</span>
							:
							<span>{(item.profitLossAmount ? item.profitLossAmount : 0)}</span>
						  }
						</td>
						<td data-label="Cycle">
						  <button  className="plain-button-cycle">
							{item.subscriptionPeriod ? item.subscriptionPeriod : 0} days
						  </button>
						</td>
						<td data-label="Enable Trading">
							<button  className="plain-button-cycle">
							{item.openTrades ? item.openTrades : 0}
						  </button>
						</td>
						<td data-label="Enable Trading">
							<Switch 
								onChange={() => handleOpenTradeUpdate(item, index)} 
								checked={item.enableTrade} 
							/>
						</td>
						<td data-label="Action">
						  <button onClick={() => {onUpdateAction(item, index)}} className="plain-button">
							Update
						  </button>
						</td>
						<td data-label="Remove">
						  <button onClick={() => {setOpenDelete(true); setCurrentIndex(index);setCurrentUser(item);}} className="plain-button-remove">
							Remove
						  </button>
						</td>
					  </tr>
					))  
				)}
				</tbody>
				</table>
			</div>
          );
    }

	// Calculate the total number of pages
	const totalPages = Math.ceil(recordsFiltered / recordsPerPage);

	// Function to handle page navigation
	const handlePageChange = (page) => {
		setCurrentPage(page);
	}

	// Render pagination buttons
	const renderPagination = () => {
		const buttons = [];

		// Add Previous button if currentPage is greater than 1
		if (currentPage > 1) {
			buttons.push(
				<button className='paginationbutton' key="prev" onClick={() => handlePageChange(currentPage - 1)}>Prev</button>
			);
		}

		// Calculate the starting page number for pagination
		let startPage = 1;
		if (startPage <= 0) {
			startPage = 1;
		}

		// Calculate the ending page number for pagination
		let endPage = startPage + 3;
		if (endPage > totalPages) {
			endPage = totalPages;
		}

		// Render pagination buttons for each page
		for (let i = startPage; i <= endPage; i++) {
			buttons.push(
				<button className={`paginationbutton ${currentPage === i ? 'paginationbuttonActive' : ''}`} key={i} onClick={() => handlePageChange(i)}>{i}</button>
			);
		}

		// Add Next button if currentPage is less than totalPages
		if (currentPage < totalPages) {
			buttons.push(
				<button className='paginationbutton' key="next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
			);
		}

		return buttons;
	}

    const onUpdateAction = (item, index) => {
		setCurrentUser(item);
		setSelectType(item.profitLossType)
		setCurrentIndex(index);
		setOpenUpdate(true);
		console.log('$$$$$$$$$$$$$$', currentUser);
    }

	const onCloseUpdate = () => {
		setOpenUpdate(false)
	}

	const onCloseView = () => {
		setOpenView(false)
	}

	const onCloseDelete = () => {
		setOpenDelete(false)
	}

	const handleDropdownChange = (event) => {
        setSelectType(event.target.value);
      };

	const save = () => {
		setButtonLoader(true)
		let token = Cookies.get('token');
		let headers= {
			Authorization : `Bearer ${token}`
		}
		console.log(currentUser);
		let apiRequest = {
			"userId" : currentUser.id,
			"subscriptionPeriod": currentUser.subscriptionPeriod,
			"openTrades" : currentUser.openTrades
		}
		if(balanceUpdate) {
			apiRequest.type = selectType;
			apiRequest.amount = currentUser.profitLossAmount.replace(/[^0-9.]+/g, "");
		}
		// console.log('apiRequest', apiRequest)
		axios.post('https://backend.luxara.ai/api/v1/updateBalance',apiRequest, {headers: headers})
        .then((apiResponse)=>{
            // console.log('apiResponse', apiResponse.data);
			let temp = [...data];
			currentUser.profitLossType = selectType;
			if(selectType === 'Profit') {
				currentUser.balance = Number(currentUser.balance) + Number(currentUser.profitLossAmount);
			} else if (selectType === 'Loss') {
				currentUser.balance = Number(currentUser.balance) - Number(currentUser.profitLossAmount);
			}
			temp[currentIndex] = currentUser;
			// console.log('currentUser@@@@@@@@', currentUser)
			// setData(temp);
			getUsers();
			setSelectType('')
			setButtonLoader(false);
			setOpenUpdate(false);
        })
        .catch((apiError)=>{
			setLoading(false);
			setOpenUpdate(false);
			console.log('apiError', apiError.messsage);
			setButtonLoader(false);
			if(apiError.response.status == 401){
				navigate('/login')
			}
        })
	}

	const customStyles = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)',
		  ...(isMobile ? {minWidth: '250px'} : { minWidth: '300px' }), 
		  padding: '20px',
		  borderRadius: '8px',
		  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
		  backgroundColor: '#fff',
		},
	  };

	const AddUser = () => {
		navigate('/adduser')
	}

	const DeletePopUp = () => {
		return (
			<Modal
				isOpen={openDelete}
				onRequestClose={onCloseDelete}
				contentLabel="Delete Modal"
				style={customStyles}
			>
				<div className='flexRow' style={{justifyContent:'flex-end', alignItems:'center'}}>
					<button
					className='closeIcon' 
					onClick={onCloseDelete}
					>X</button>
				</div>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10}}>
					<span className='fieldName'>Are you sure to remove {currentUser.userName} ?</span>
				</div>
				<div className='flexRow' style={{marginTop:15, justifyContent:'space-between', alignItems:'center'}}>
				<div>
					<button
					onClick={onCloseDelete}
					className='plain-button-remove'
					style={{backgroundColor:'#FFF'}}
					>
						Cancel
					</button>
				</div>
				{
					buttonLoader ? 
					<div className='save flexRow alignCenter'>
						<ButtonLoader />
					</div>
					:
					<button
					onClick={onDeleteAction}
					className='save'
					>
						Remove
					</button>
				}
				</div>
			</Modal>
		)
	}

	const UpdatePopUp = () => {
		return (
			<Modal
				isOpen={openUpdate}
				onRequestClose={onCloseUpdate}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center'}}>
					<span className='username'>{currentUser.userName}</span>
					<button
					className='closeIcon' 
					onClick={onCloseUpdate}
					>X</button>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10}}>
					<span className='fieldName'>Profit/ Loss</span>
					{/* <input
					className='numberInput'
					value={currentUser.balance}
					onChange={(text)=>{
						let temp = {
							id: currentUser.id,
							userName: currentUser.userName,
							balance: text.target.value,
							profitLossAmount: currentUser.profitLossAmount,
							profitLossType : currentUser.profitLossType
						}
						console.log('temp', temp)
						setCurrentUser(temp);
					}}
					></input> */}
					<select value={selectType} 
					className='dropDownInput'
					onChange={handleDropdownChange}>
					<option value="">Select</option>
					<option value="Profit">Profit</option>
					<option value="Loss">Loss</option>
				</select>
				</div>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10}}>
					<span className='fieldName'>Amount</span>
					<input
					type="text"
					className='numberInput'
					value={currentUser.profitLossAmount ? currentUser.profitLossAmount.replace(/[^0-9.]+/g, "") : "0"}
					onChange={(e)=>{
						let inputValue = e.target.value;

						inputValue = inputValue.replace(/^0+(?=\d)/, '');

						if(currentUser.profitLossAmount && inputValue !== currentUser.profitLossAmount.replace(/[^0-9.]+/g, "")){
							setBalanceUpdate(true);
						}

						// Allow only numbers, decimal points, and optional leading '-' sign
						if (/^\d*\.?\d*$/.test(inputValue)) {
							let temp = {
								id: currentUser.id,
								userName: currentUser.userName,
								balance: currentUser.balance,
								profitLossAmount: inputValue,
								profitLossType: currentUser.profitLossType,
								openTrades: currentUser.openTrades,
								subscriptionPeriod : currentUser.subscriptionPeriod
							};
							setCurrentUser(temp);
						}
					}}
					></input>
				</div>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10}}>
					<span className='fieldName'>Cycle days</span>
					<input
					type='number'
					className='numberInput'
					value={currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0}
					onChange={(e)=>{
						let inputValue = e.target.value;
						let temp = {
							id : currentUser.id,
							userName: currentUser.userName,
							balance: currentUser.balance,
							profitLossAmount: currentUser.profitLossAmount,
							profitLossType: currentUser.profitLossType,
							openTrades: currentUser.openTrades,
							subscriptionPeriod : inputValue
						};
							setCurrentUser(temp);
					}}
					></input>
				</div>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10}}>
					<span className='fieldName'>Open Trades</span>
					<input
					type='number'
					className='numberInput'
					value={currentUser.openTrades ? currentUser.openTrades : 0}
					onChange={(e)=>{
						let inputValue = e.target.value;
						let temp = {
							id : currentUser.id,
							userName: currentUser.userName,
							balance: currentUser.balance,
							profitLossAmount: currentUser.profitLossAmount,
							profitLossType: currentUser.profitLossType,
							openTrades: inputValue,
							subscriptionPeriod : currentUser.subscriptionPeriod
						};
						console.log(inputValue);
							setCurrentUser(temp);
					}}
					></input>
				</div>
				<div className='flexRow alignCenter' style={{marginTop:15}}>
				{
					buttonLoader ? 
					<div className='save flexRow alignCenter'>
						<ButtonLoader />
					</div>
					:
					<button
					onClick={save}
					className='save'
					>
						Save
					</button>
				}
				</div>
			</Modal>
		)
	}

	const renderPersonalData = () => {
        return (
            <>{
                loading ? (
                  <div className="skeleton profileCard flexCol">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : 
                <div className="profileCard flexCol" style={{width:'auto'}}>
					<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center'}}>
						<span className='username'>Yeswanth</span>
						<button
						style={{backgroundColor:'#E31892', color:'#FFF'}}
						onClick={onCloseView}
						>X</button>
					</div>
					<hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">First Name</span>
                        </div>
						{
							firstName ? 
							<div>
								<span className="name">{firstName}</span>
							</div>
							:
							<div className="skeleton profileCard flexCol" style={{paddingBottom:0, paddingTop:0}}>
								<div className="skeleton-line"></div>
							</div>
						}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Last Name</span>
                        </div>
                        {
							lastName ? 
							<div>
								<span className="name">{lastName}</span>
							</div>
							:
							<div className="skeleton profileCard flexCol" style={{paddingBottom:0, paddingTop:0}}>
								<div className="skeleton-line"></div>
							</div>
						}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Mobile Number</span>
                        </div>
                        {
							mobileNumber ? 
							<div>
								<span className="name">{mobileNumber}</span>
							</div>
							:
							<div className="skeleton profileCard flexCol" style={{paddingBottom:0, paddingTop:0}}>
								<div className="skeleton-line"></div>
							</div>
						}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Email</span>
                        </div>
                        {
							email ? 
							<div>
								<span className="name">{email}</span>
							</div>
							:
							<div className="skeleton profileCard flexCol" style={{paddingBottom:0, paddingTop:0}}>
								<div className="skeleton-line"></div>
							</div>
						}
                    </div>
					<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Balance</span>
                        </div>
                        {
							balance ? 
							<div>
								<span className="name">{balance}</span>
							</div>
							:
							<div className="skeleton profileCard flexCol" style={{paddingBottom:0, paddingTop:0}}>
								<div className="skeleton-line"></div>
							</div>
						}
                    </div>
                    {/* <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Password</span>
                        </div>
                        {renderField('password', 'Password')}
                    </div> */}
                </div>
              }
            </>
        )
    }


	const customStylesWithoutBackgroundAndPadding = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)',
		  ...(isMobile ? { minWidth: '250px' } : { minWidth: '500px' }),
		  borderRadius: '12px',
		  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
		  padding:0
		  // Exclude background color and padding
		},
	  };

	const ViewDetailsPopUp = () => {
		return (
			<Modal
				isOpen={openView}
				onRequestClose={onCloseView}
				contentLabel="Example Modal"
				style={customStylesWithoutBackgroundAndPadding}
			>
				{
					renderPersonalData()
				}
			</Modal>
		)
	}

	const handleSearchKeyPress = (event) => {
		if (event.key === 'Enter') {
			console.log(event.key);
			setCurrentPage(1); // Reset to page 1 for new search results
			getUsers(1, searchValue); // Fetch users based on search value
		}
	};

    return (
        <div className='dashboard'>
            <div>
                <div className='dashboarddetails'>
					<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center'}}>
						<div>
							<span className='dashboardtype leftalign' style={{padding:12, paddingTop:0, paddingBottom:24}}>Hello Admin</span>
						</div>
					</div>
					<div className='userTable'>
						<div className='topdivs'>
							<div className='flexCol' style={{alignItems:'flex-start'}}>
								<div className='allusers' >
									<span>All Users</span>
								</div>
								<div className='activemem'>
									<span>Active Members</span>
								</div>
							</div>
							<div className='topfeatures'>
								<div style={{width:'95%'}}>
									<input 
									onKeyDown={handleSearchKeyPress}
									onChange={(e) => setSearchValue(e.target.value)}
									className='searchInput'
									placeholder='Search'
									/>
								</div>	
								<div style={{width:'100%'}}>
									<select value={selectType} 
										style={{minWidth:150}}
										className='searchInput'
										onChange={handleDropdownChange}>
										<option value="">Select</option>
										<option value="Profit">Newest</option>
										<option value="Loss">Oldest</option>
									</select>
								</div>
								<div style={{width:'95%'}}>
									<button 
									onClick={AddUser}
									className='plain-button-add'>
										+ Add User
									</button>
								</div>
							</div>
						</div>
						
						{
							renderTableData()
						}
						<div className='flexRow' style={{justifyContent:'space-between', alignItems: 'center', padding:24}}>
							<div>
								<span className='paginationResultText'>
									Showing {(currentPage - 1) * 10 +1} to {currentPage*10 > recordsFiltered ? recordsFiltered : currentPage*10} of {recordsFiltered} users
								</span>
							</div>
							<div className='flexRow' style={{gap:16}}>
							{
								renderPagination()
							}
							</div>
						</div>
						

						{/* <div>
							<div>
								<span>
									Showing results 1 to 10 of 50 entries;
								</span>
							</div>
							<div>
								<div>
									<button>{buttonCount}</button>
								</div>
							</div>
						</div> */}
					</div>
					{
						UpdatePopUp()
					}
					{
						DeletePopUp()
					}
					{
						ViewDetailsPopUp()
					}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;