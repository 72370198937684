import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import '../Styles/TopBar.css';
import TokenParser from '../Helpers/tokenParser';

const Topbar = ({tabSelected, setTabSelected}) => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    useEffect(()=>{
        let token = Cookies.get('token');
        let details = TokenParser(token);
        console.log('$$$$$$$$$$$$details', details)
        let firstLetter = details.payload?.username;
        if(firstLetter){
            setFirstName(firstLetter.substring(0, 1))
        }
    }, [])

    const openProfile = () => {
        setTabSelected('Profile');
        navigate('/profile')
    }
    return (
        <div className='mobileTopBar'>
            <div className='TopBar flexRow mobileTopBarWidth' style={{alignItems:'center', justifyContent: 'space-between'}}>
                <div className='tabSelectedname'>
                    <span className='title'>{tabSelected}</span>
                </div>
                <div className='logoImagedisplay'>
                    <img style={{height:36}} src={require('../assets/logoBanner.svg').default} />
                </div>
                <div className='flexRow alignCenter g24'>
                    {/* <div className="input-wrapper noMobile">
                        <img className='icon' style={{width:24, height:24}} src={require('../assets/search.svg').default} />
                        <input type="text" className="input-field" placeholder="Search" />
                    </div> */}
                    <div className='flexRow alignCenter'>
                        <img style={{width:32, height:32}} src={require('../assets/notification.svg').default} />
                    </div>
                    <div onClick={openProfile} style={{cursor:'pointer'}}>
                        {
                            false ? 
                            <div className="avatar">
                                <img src={require('../assets/testImage.svg').default} />
                            </div>
                            :
                            <div className="avatarText flexRow alignCenter">
                                <span className='avatarLetter'>
                                    {firstName}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Topbar;