import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import App from "./App";
import Login from '../src/Screens/Login'
import ResetPassword from '../src/Screens/ResetPassword'
import Dashboard from "./Screens/DashBoard";
import Profile from "./Screens/Profile";
import Trade from "./Screens/Trade";
import UserDashboard from "./Screens/UserDashBoard";
import AddUser from "./Screens/AddUser";
import UserDashBoardAdminView from "./Screens/UserDashBoardAdminView";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: '/',
        element: <UserDashboard />
      },
      {
        path: '/admin/home',
        element: <Dashboard />
      },
      {
        path: '/admin/users/:userId',
        element: <UserDashBoardAdminView />
      },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: '/trade',
        element: <Trade />
      },
      {
        path: '/adduser',
        element: <AddUser />
      }
    ]
  },
  

]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);