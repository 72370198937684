import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import '../Styles/Profile.css';
import axios from 'axios';
import '../Styles/Dashboard.css';
import ButtonLoader from '../Helpers/buttonLoader';

const Profile = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword,setShowPassword] = useState(false);
    const [showNewPassword,setShowNewPassword] = useState(false);
    const [showConfirmPassword,setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [openUpdate, setOpenUpdate] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [editableField, setEditableField] = useState({
        firstName: false,
        lastName: false,
        mobileNumber: false,
        email: false,
        password: false
    });

    const onCloseUpdate = () => {
		setOpenUpdate(false)
	}

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        password: '',
    })
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        password: '',
    });

    const handleUpdatePassword = () => {
        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            Authorization : `Bearer ${token}`
        }
        let apiRequest = {
            "isNewUser": "True",
            "oldPassword" : password,
            "newPassword" : confirmPassword
        }
        console.log('apiRequest', apiRequest);
        axios.put('https://backend.luxara.ai/api/v1/updatePassword', apiRequest, {headers: headers})
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            setLoading(false);
            setOpenUpdate(false);
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError', apiError.messsage);
            setError(apiError.messsage);
            setLoading(false);
			
        })
    };

    const getUserDetails = () => {
        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            Authorization : `Bearer ${token}`
        }
        axios.get('https://backend.luxara.ai/api/v1/getUserProfile', {headers: headers})
        .then((apiResponse)=>{
            console.log('apiResponse', apiResponse.data);
            let tempdata = {
                firstName: apiResponse.data.firstName,
                lastName: apiResponse.data.lastName,
                mobileNumber: apiResponse.data.phoneNumber,
                email: apiResponse.data.email,
            }
            console.log('tempdataaaa', tempdata)
            setUserData(tempdata);
            setFormData(tempdata);
			setLoading(false);
        })
        .catch((apiError)=>{
			setLoading(false);
            console.log('apiError', apiError.message);
			if(apiError.response.status == 401){
				navigate('/login')
			}
        })
    }

    useEffect(()=>{
        getUserDetails();
    }, [])
    
    useEffect(()=> {
        renderPersonalData();
    }, [editableField])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdate = () => {
        setButtonLoader(true);
        console.log('Updated data:', formData);
        let mt = formData.mobileNumber;
        formData.phoneNumber = formData.mobileNumber;
        formData.mobileNumber = undefined;
        let newData = new FormData();
        newData.append("request", JSON.stringify(formData));
        formData.mobileNumber = mt;
        let token = Cookies.get('token');
        let headers = {
            Authorization : `Bearer ${token}`
        }
        axios.post('https://backend.luxara.ai/api/v1/updateUserProfile',newData, {headers: headers})
        .then((apiResponse)=>{
            console.log('apiResponse', apiResponse.data);
            setEditableField({
                firstName: false,
                lastName: false,
                mobileNumber: false,
                email: false,
                password: false
            });
			setButtonLoader(false);
        })
        .catch((apiError)=>{
			setButtonLoader(false);
            console.log('apiError', apiError.message);
			if(apiError.response.status == 401){
				navigate('/login')
			}
        })
    };

    const handleCancel = () => {
        // Reset editable field
        setEditableField({
            firstName: false,
            lastName: false,
            mobileNumber: false,
            email: false,
            password: false
        });
        // Reset form data to initial values
        let ut = userData;
        setFormData(ut);
        navigate('/');
    };

    const editFieldAction = (field) => {
        setEditableField({ ...editableField, [field]: true });
    }

    const onUpdateAction = () => {
		setOpenUpdate(true);
    }


    const renderField = (field, label) => {
        return editableField[field] ? (
            <input
                type="text"
                name={field}
                value={formData[field]}
                onChange={handleChange}
                autoFocus
                className='inputTextField'
            />
        ) : (
            <div className="flexRow alignCenter g12" style={{ cursor: 'pointer' }} onClick={() => editFieldAction(field)}>
                <span className="value">{formData[field]}</span>
                {editableField[field] !== true && (
                    <img
                        style={{ width: 14, height: 14, cursor: 'pointer' }}
                        src={require('../assets/editICon.svg').default}
                        alt={`Edit ${field}`}
                    />
                )}
            </div>
        );
    };

    const customStyles = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
          width: '500px',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)',
		  ...(isMobile ? {minWidth: '250px'} : { minWidth: '300px' }), 
		  padding: '20px',
		  borderRadius: '8px',
		  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
		  backgroundColor: '#fff',
		},
	  };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const UpdatePopUp = () => {
		return (
			<Modal
				isOpen={openUpdate}
				onRequestClose={onCloseUpdate}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center'}}>
                <span className='username'>Update Password</span>
					<button
					className='closeIcon' 
					onClick={onCloseUpdate}
					>X</button>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
				<div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10, position : 'relative'}}>
					<span className='fieldName'>Current Password</span>
					<input
					type={showPassword ? 'text' : 'password'}
                    className='numberInput'
					onChange={(e)=>{
						let inputValue = e.target.value;
						setPassword(inputValue);
					}}
					></input>
                    <button 
                                type="button" 
                                onClick={toggleShowPassword}
                                className = "password-toggle-btn"
                                >
                                {showPassword ? 'Hide' : 'Show'}  
                            </button>
                    
				</div>
                <div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10, position : 'relative'}}>
					<span className='fieldName'>New Password</span>
					<input
					type={showNewPassword ? 'text' : 'password'}
                    className='numberInput'
					onChange={(e)=>{
						let inputValue = e.target.value;
						setNewPassword(inputValue);
					}}
					></input>
                    <button 
                                type="button" 
                                onClick={toggleShowNewPassword}
                                className = "password-toggle-btn"
                                >
                                {showNewPassword ? 'Hide' : 'Show'}  
                            </button>
				</div>
                <div className='flexRow' style={{justifyContent:'space-between', alignItems:'center', marginTop:10, position : 'relative'}}>
					<span className='fieldName'>Confirm Password</span>
					<input
					type={showConfirmPassword ? 'text' : 'password'}
                    className='numberInput'
					onChange={(e)=>{
						let inputValue = e.target.value;
                        setConfirmPassword(inputValue);
					}}
					/>
                    <button 
                                type="button" 
                                onClick={toggleShowConfirmPassword}
                                className = "password-toggle-btn"
                                >
                                {showConfirmPassword ? 'Hide' : 'Show'}  
                            </button>
				</div>
				<div className='flexRow alignCenter' style={{marginTop:15}}>
				{
					buttonLoader ? 
					<div className='save flexRow alignCenter'>
						<ButtonLoader />
					</div>
					:
					<button
					onClick={handleUpdatePassword}
					className='save'
					>
						Update
					</button>
				}
				</div>
			</Modal>
		)
	}

    const renderPersonalData = () => {
        return (
            <>{
                loading ? (
                  <div className="skeleton profileCard flexCol">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : 
                <div className="profileCard flexCol">
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">First Name</span>
                        </div>
                        {renderField('firstName', 'First Name')}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Last Name</span>
                        </div>
                        {renderField('lastName', 'Last Name')}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Mobile Number</span>
                        </div>
                        {renderField('mobileNumber', 'Mobile Number')}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Email</span>
                        </div>
                        {renderField('email', 'Email')}
                    </div>
                    <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Password</span>
                        </div>
                        <button className="updateButton" onClick={() => {onUpdateAction()}}>
                                Update
                        </button>
                    </div>
                    {/* <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Password</span>
                        </div>
                        {renderField('password', 'Password')}
                    </div> */}
                </div>
              }
            </>
        )
    }

    return (
        <div className='mobileProfile'>
            <div className="flexCol alignCenter g12">
                <div className="profileTitle">
                    <span className="name">Personal Info</span>
                </div>
                {
                    renderPersonalData()
                }
                <div className="flexRow updatebuttons" style={{justifyContent: 'space-between',
    alignItems: 'center',}}>
                    <div>
                        {
                            buttonLoader ?
                            <div className="LoginButton flexRow alignCenter">
                                <ButtonLoader />
                            </div>
                            :
                            <button className="updateButton" onClick={handleUpdate}>
                                Update Details
                            </button>

                        }
                    </div>
                    <div>
                        <button className="cancelButton" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
                {
                    UpdatePopUp()
                }
            </div>
        </div>
    );
};

export default Profile;
